<template>
  <div class="loan-calculator">
    <h2>Boliglånskalkulator</h2>
    <p>Regn ut hvor mye boliglånet vil koste månedlig og totalt, og vurder faktorer som påvirker låneevnen din.</p>

    <div class="input-group-container">
      <!-- Lånetype -->
      <div class="input-group">
        <label>Lånetype:</label>
        <select v-model="loanType">
          <option value="annuitetslån">Annuitetslån</option>
          <option value="serielån">Serielån</option>
          <option value="rammelån">Rammelån</option>
        </select>
      </div>

      <!-- Inntekt -->
      <div class="input-group">
        <label>Inntekt:</label>
        <input type="number" v-model.number="income" placeholder="0" />
        <span class="input-description">{{ formatNumber(income) }} kr</span>
      </div>

      <!-- Egenkapital -->
      <div class="input-group">
        <label>Egenkapital:</label>
        <input type="number" v-model.number="equity" placeholder="0" />
        <span class="input-description">{{ formatNumber(equity) }} kr</span>
      </div>

      <!-- Boligens pris -->
      <div class="input-group">
        <label>Boligens pris:</label>
        <input type="text" :placeholder="formatNumber(defaultHomePrice)" v-model="homePriceInput" @input="updateHomePrice" @blur="validateHomePrice" />
        <span class="input-description">{{ formatNumber(homePrice) }} kr</span>
      </div>

      <!-- Rente -->
      <div class="input-group">
        <label>Rente (%):</label>
        <input type="text" :placeholder="defaultInterestRate.toFixed(2) + ' %'" v-model="interestRateInput" @input="updateInterestRate" @blur="validateInterestRate" />
        <span class="input-description">{{ interestRate.toFixed(2) }} %</span>
      </div>

      <!-- Øvrig Gjeld -->
      <div class="debt-section">
        <h3>Øvrig Gjeld</h3>
        <div class="input-group">
          <label>Studielån:</label>
          <input type="number" v-model.number="studentLoan" placeholder="0" />
        </div>
        <div class="input-group">
          <label>Forbruksgjeld:</label>
          <input type="number" v-model.number="consumerDebt" placeholder="0" />
        </div>
        <div class="input-group">
          <label>Billån:</label>
          <input type="number" v-model.number="carLoan" placeholder="0" />
        </div>
        <div class="input-group">
          <label>Kredittkortgrense:</label>
          <input type="number" v-model.number="creditLimit" placeholder="0" />
        </div>
      </div>

      <!-- Antall barn og Nedbetalingstid -->
      <div class="input-group">
        <label>Antall barn:</label>
        <input type="number" v-model.number="numChildren" placeholder="0" />
      </div>
      <div class="input-group">
        <label>Nedbetalingstid (år):</label>
        <input type="number" :placeholder="defaultLoanTerm + ' år'" v-model="loanTermInput" @input="updateLoanTerm" @blur="validateLoanTerm" min="1" />
        <span class="input-description">{{ loanTerm }} år</span>
      </div>

      <!-- Beregn-knapp -->
      <button class="calculate-button" @click="calculateLoan">Beregn</button>
    </div>

    <!-- Resultater, vises kun etter beregning -->
    <div v-if="maxPurchasePrice !== null" class="results-card">
      <h3>Maksimal kjøpesum</h3>
      <p>Du kan kjøpe bolig for opptil: <strong>{{ formatNumber(maxPurchasePrice) }} kr</strong></p>
      <p>Beregning:</p>
      <ul>
        <li>Maks lånebeløp basert på inntekt (5x inntekt): {{ formatNumber(income * 5) }} kr</li>
        <li>Samlet gjeld (studielån, forbruksgjeld, billån, kredittkort): {{ formatNumber(totalDebt) }} kr</li>
        <li>Egenkapital: {{ formatNumber(equity) }} kr</li>
        <li><strong>Justert kjøpesum: {{ formatNumber(maxPurchasePrice) }} kr</strong></li>
      </ul>
    </div>

    <div v-if="monthlyPayment !== null" class="results-card">
      <h3>Lånekostnad</h3>
      <p>Månedlig kostnad: <strong>{{ formatNumber(monthlyPayment) }} kr</strong></p>
      <p>Total kostnad: <strong>{{ formatNumber(totalCost) }} kr</strong></p>
    </div>

    <div v-if="surplusOrDeficitEquity !== null" class="results-card">
      <h3>Egenkapitaltilovers/mangler</h3>
      <p :style="{ color: surplusOrDeficitEquity >= 0 ? 'green' : 'red' }">
        {{ surplusOrDeficitEquity >= 0 ? 'Egenkapital til overs:' : 'Mangler egenkapital:' }}
        <strong>{{ formatNumber(Math.abs(surplusOrDeficitEquity)) }} kr</strong>
      </p>
    </div>
    <div class="interest-increase-table">
    <h3>Hvis det blir en renteøkning</h3>
    <table>
      <thead>
        <tr>
          <th>Renteøkning</th>
          <th v-for="scenario in interestScenarios" :key="scenario.rateIncrease">
            {{ (scenario.rateIncrease * 100).toFixed(2) }}%
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Ny månedlig terminbeløp</td>
          <td v-for="scenario in interestScenarios" :key="'payment-' + scenario.rateIncrease">
            {{ formatCurrency(scenario.newMonthlyPayment) }}
          </td>
        </tr>
        <tr>
          <td>Total lånekostnad</td>
          <td v-for="scenario in interestScenarios" :key="'totalCost-' + scenario.rateIncrease">
            {{ formatCurrency(scenario.totalCost) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

    <!-- kode for renteøkningstabell -->

  </div>

  <div v-if="amortizationSchedule.length" class="amortization-schedule">
  <h3>Nedbetalingsplan</h3>
  <table>
    <thead>
      <tr>
        <th>Måned/År</th>
        <th>Terminbeløp</th>
        <th>Rente</th>
        <th>Avdrag</th>
        <th>Gjenstående saldo</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="entry in amortizationSchedule" :key="entry.month">
        <td>{{ entry.month <= 24 ? `Måned ${entry.month}` : `År ${Math.ceil(entry.month / 12)}` }}</td>
        <td>{{ formatNumber(entry.monthlyPayment) }} kr</td>
        <td>{{ formatNumber(entry.interestPayment) }} kr</td>
        <td>{{ formatNumber(entry.principalPayment) }} kr</td>
        <td>{{ formatNumber(entry.remainingBalance) }} kr</td>
      </tr>
    </tbody>
  </table>
</div>

</template>

<script>
export default {
  data() {
    return {
      income: 650000,
      equity: 700000,
      homePrice: 3500000,
      interestRate: 5.49,
      loanTerm: 30,
      loanType: 'annuitetslån',
      childCostPerYear: 60000,
      defaultHomePrice: 3500000,
      defaultInterestRate: 5.49,
      defaultLoanTerm: 30,
      homePriceInput: "",
      interestRateInput: "",
      loanTermInput: "",
      studentLoan: 700000,
      consumerDebt: 0,
      carLoan: 0,
      creditLimit: 25000,
      numChildren: 0,
      monthlyPayment: null,
      totalCost: null,
      maxPurchasePrice: null,
      amortizationSchedule: [], // New variable for amortization schedule
      totalDebt: 0, // Adding totalDebt here
      errorMessage: "",
      // Define interest scenarios
      interestScenarios: [
        { rateIncrease: 0.0025, newMonthlyPayment: 0, totalCost: 0 },
        { rateIncrease: 0.005, newMonthlyPayment: 0, totalCost: 0 },
        { rateIncrease: 0.0075, newMonthlyPayment: 0, totalCost: 0 },
        { rateIncrease: 0.01, newMonthlyPayment: 0, totalCost: 0 },
        { rateIncrease: 0.015, newMonthlyPayment: 0, totalCost: 0 },
        { rateIncrease: 0.02, newMonthlyPayment: 0, totalCost: 0 },
        { rateIncrease: 0.025, newMonthlyPayment: 0, totalCost: 0 },
        { rateIncrease: 0.03, newMonthlyPayment: 0, totalCost: 0 },
      ],
      scenarioResults: [] // Result storage for display
    };
  },
  computed: {
    requiredEquity() {
      return this.homePrice * 0.15;
    },
    surplusOrDeficitEquity() {
      return this.equity - this.requiredEquity;
    },
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat("no-NO", {
        style: "currency",
        currency: "NOK"
      }).format(value);
    },
    validateLoanRequirements() {
      if (this.equity < this.requiredEquity) {
        this.errorMessage = `Egenkapitalen må være minst ${this.formatNumber(this.requiredEquity)} kr for å kvalifisere for lånet.`;
        return false;
      }
      const maxLoanAmount = this.income * 5;
      const totalLoanAmount = this.homePrice - this.equity;
      if (totalLoanAmount > maxLoanAmount) {
        this.errorMessage = `Du kan ikke låne mer enn ${this.formatNumber(maxLoanAmount)} kr basert på inntekten din.`;
        return false;
      }

      const annualChildCost = this.numChildren * this.childCostPerYear;
      const adjustedIncome = this.income - annualChildCost;

      if (adjustedIncome < totalLoanAmount / 5) {
        this.errorMessage = `Barnas kostnader reduserer låneevnen din. Med ${this.numChildren} barn må inntekten være høyere.`;
        return false;
      }

      this.errorMessage = "";
      return true;
    },

    calculateLoan() {
  if (!this.validateLoanRequirements()) return;

  const monthlyRate = this.interestRate / 100 / 12;
  const numberOfPayments = this.loanTerm * 12;
  const loanAmount = this.homePrice - this.equity;

  let schedule = [];
  let remainingBalance = loanAmount;

  for (let i = 1; i <= numberOfPayments; i++) {
    let interestPayment = remainingBalance * monthlyRate;
    let principalPayment;

    if (this.loanType === 'annuitetslån') {
      this.monthlyPayment = Math.round(
        (loanAmount * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -numberOfPayments))
      );
      principalPayment = this.monthlyPayment - interestPayment;
    } else if (this.loanType === 'serielån') {
      const principalAmount = loanAmount / numberOfPayments;
      principalPayment = principalAmount;
      this.monthlyPayment = Math.round(principalPayment + interestPayment);
    } else if (this.loanType === 'rammelån') {
      this.monthlyPayment = Math.round(loanAmount * monthlyRate); // For rammelån
      principalPayment = 0; // Ingen nedbetaling av hovedstolen
    }

    remainingBalance -= principalPayment;

    if (i <= 24 || i % 12 === 0) {
      schedule.push({
        month: i,
        monthlyPayment: Math.round(this.monthlyPayment),
        interestPayment: Math.round(interestPayment),
        principalPayment: Math.round(principalPayment),
        remainingBalance: Math.round(remainingBalance),
      });
    }
  }

  this.amortizationSchedule = schedule;
  this.totalCost = this.monthlyPayment * numberOfPayments;

  // Legg til console.log for å inspisere verdiene
  console.log("Månedlig betaling:", this.monthlyPayment);
  console.log("Total kostnad:", this.totalCost);

  // Kall på metoden for å beregne maksimal kjøpesum
  this.calculateMaxPurchasePrice();

  // Kall på metoden for å beregne renteøkning-scenarioer
  this.calculateInterestScenarios();
},
calculateInterestScenarios() {
  const loanAmount = this.homePrice - this.equity;
  const numberOfPayments = this.loanTerm * 12;

  // Define increments for interest rate increases
  const interestIncreases = [0.0025, 0.005, 0.0075, 0.01, 0.015, 0.02, 0.025, 0.03];

  // Update each scenario in interestScenarios with the new calculations
  this.interestScenarios = interestIncreases.map(increase => {
    // Calculate the new monthly interest rate based on the increment
    const newMonthlyRate = (this.interestRate / 100 + increase) / 12;

    // Calculate the new monthly payment using the annuity formula
    const newMonthlyPayment = Math.round(
      (loanAmount * newMonthlyRate) / (1 - Math.pow(1 + newMonthlyRate, -numberOfPayments))
    );

    // Calculate the total loan cost over the entire loan term
    const totalCost = newMonthlyPayment * numberOfPayments;

    return {
      rateIncrease: increase, // Keep the increase in decimal form
      newMonthlyPayment,
      totalCost,
    };
  });
},


    calculateMaxPurchasePrice() {
      this.totalDebt = this.studentLoan + this.consumerDebt + this.carLoan + this.creditLimit;
      const maxLoanAmount = this.income * 5;
      const adjustedLoanAmount = maxLoanAmount - this.totalDebt;
      this.maxPurchasePrice = adjustedLoanAmount + this.equity;
    },
    formatNumber(value) {
      return new Intl.NumberFormat("no-NO").format(value);
    },
    updateHomePrice() {
      const parsedValue = parseInt(this.homePriceInput.replace(/\s/g, ""), 10);
      this.homePrice = isNaN(parsedValue) ? this.defaultHomePrice : parsedValue;
    },
    validateHomePrice() {
      this.homePriceInput = this.formatNumber(this.homePrice);
    },
    updateInterestRate() {
      const parsedValue = parseFloat(this.interestRateInput.replace(",", "."));
      this.interestRate = isNaN(parsedValue) ? this.defaultInterestRate : parsedValue;
    },
    validateInterestRate() {
      this.interestRateInput = this.interestRate.toFixed(2);
    },
    updateLoanTerm() {
      const parsedValue = parseInt(this.loanTermInput, 10);
      if (parsedValue > 30) {
        this.loanTerm = 30;
        this.errorMessage = "Maksimal nedbetalingstid er 30 år. Verdien er automatisk satt til 30 år.";
      } else {
        this.loanTerm = isNaN(parsedValue) ? this.defaultLoanTerm : parsedValue;
        this.errorMessage = "";
      }
    },
    validateLoanTerm() {
      this.loanTermInput = this.loanTerm.toString();
    },
  watch: {
    income: "calculateMaxPurchasePrice",
    equity: "calculateMaxPurchasePrice",
    studentLoan: "calculateMaxPurchasePrice",
    consumerDebt: "calculateMaxPurchasePrice",
    carLoan: "calculateMaxPurchasePrice",
    creditLimit: "calculateMaxPurchasePrice",
        formatCurrency(value) {
      return new Intl.NumberFormat("no-NO", {
        style: "currency",
        currency: "NOK",
      }).format(value);}}}};
</script>



<style scoped>
/* Generelle stiler */
.loan-calculator {
  max-width: 1000px;
  margin: auto;
  padding: 2em;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

/* Desktop grid layout */
@media (min-width: 768px) {
  .input-group-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5em; /* Mer avstand mellom kolonner */
  }
  
  .debt-section,
  .results-card {
    grid-column: span 2;
  }
}

/* Input-grupper og beskrivende tekst */
.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

.input-description {
  color: #666;
  font-size: 0.9em;
  margin-top: 0.3em;
}

h2 {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 0.5em;
  text-align: center;
}

p {
  color: #666;
  font-size: 1em;
  margin-bottom: 1.5em;
  text-align: center;
}

.input-group label {
  font-weight: bold;
  color: #333;
  font-size: 1em;
}

.input-group input[type="text"],
.input-group input[type="number"],
.input-group select {
  width: 100%;
  padding: 0.6em;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  color: #333;
}

/* Resultatkort */
.results-card {
  margin-top: 1.5em;
  padding: 1.5em;
  background-color: #e0f7fa;
  border-radius: 8px;
  color: #333;
  font-size: 1em;
  text-align: left;
}

/* Beregn-knapp */
.calculate-button {
  width: 100%;
  padding: 0.8em;
  background-color: #420440;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  
}

.calculate-button:hover {
  background-color: #45a049;
}

/* Feilmelding */
.error-message {
  color: #d9534f;
  font-size: 1em;
  text-align: center;
  margin-top: 0.5em;
}

.amortization-schedule {
  margin-top: 2em;
  background-color: #f9f9f9;
  padding: 1em;
  border-radius: 8px;
}

.amortization-schedule table {
  width: 100%;
  border-collapse: collapse;
}

.amortization-schedule th, .amortization-schedule td {
  border: 1px solid #ddd;
  padding: 0.8em;
  text-align: right;
}

.amortization-schedule th {
  background-color: #e0f7fa;
  color: #333;
  font-weight: bold;
}

.amortization-schedule td {
  color: #333;
}

</style>
